import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default props => {
  const { slug, header, subheader, images } = props // from Promotion
  const { path } = props // from parent

  const firstImage = images && images[0]
  const maxWordRange = 75
  const shortSubHeader = subheader?.substring(0, maxWordRange - 3)

  return (
    <Link className="card-promotion" to={`${path}/${slug}`} alt={header}>
      <div className="card-image">
        {firstImage ? (
          <Img fluid={firstImage.image.childImageSharp.fluid} />
        ) : null}
      </div>
      <div className="card-info">
        <div className="card-title-bold">{header}</div>
        <div className="card-info-subtitle">
          {subheader?.length > maxWordRange ? `${shortSubHeader}...` : subheader}
        </div>
      </div>
    </Link>
  )
}
