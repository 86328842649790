import React from "react"
import { Link } from "gatsby"

import "./NotLoggedIn.css"

export default () => (
  <div className="not-logged-in">
    Sie müssen sich anmelden, um diesen Bereich sehen zu können.
    <br />
    <br />
    Bitte melden Sie sich <a href="#login">unten</a> an.
    <br />
    <br />
    Falls Sie noch kein Mitglied sind, können Sie sich{" "}
    <Link to="/signin">hier</Link> Anmelden.
  </div>
)
