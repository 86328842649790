import React from "react"
import { Link } from "gatsby"

import "./card.css"

export default props => {
  const { title, slug, path } = props

  return (
    <Link className="card-link" to={`${path}/${slug}`} alt={title}>
      <div className="card card-small card-flex card-border card-white-red">
        <div className="card-title-bold card-center">{title}</div>
      </div>
    </Link>
  )
}
