import React from "react"
import { Link } from "gatsby"

import "./SideMenu.css"

export default ({ selected, categories }) => {
  const allCategoriesWithoutParents = categories.filter(
    ({ parent }) => !parent.length
  )

  const renderMenue = (categorie, slug = "/service") => {
    return (
      <ul>
        {categorie.map(cat => {
          const newSlug = `${slug}/${cat.slug}`
          return (
            <li key={cat._id}>
              {cat._id === selected ? (
                cat.title
              ) : (
                <Link to={newSlug}>{cat.title}</Link>
              )}
              {cat && cat.children && cat.children.length
                ? renderMenue(cat.children, newSlug)
                : null}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="side-menue">{renderMenue(allCategoriesWithoutParents)}</div>
  )
}
