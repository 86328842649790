import React, { useContext } from "react"
import { graphql, Link, navigate } from "gatsby"

import { UserContext } from "../helpers/context"

import CardService from "../components/cards/CardService"
import CardPromotion from "../components/cards/CardPromotion"
import SideMenue from "../components/SideMenu"
import NotLoggedIn from "../components/NotLoggedIn"

export default props => {
  const { data, pageContext, path } = props
  const { younion } = data
  const { _id, parents } = pageContext
  const { promotionCategories } = younion

  const user = useContext(UserContext)
  const promoCatAllowed = promotionCategories.filter(
    promo => !promo.membersOnly || user?.sessionId
  )

  const service = promoCatAllowed.find(cat => cat._id === _id)

  if (!service) return <NotLoggedIn />

  const { info, children, promotions } = service
  const promoAllowed = promotions.filter(
    promo => !promo.membersOnly || user?.sessionId
  )

  let buildingSlug = "/service"

  if (promotions.length === 1) {
    // parent slug + children slug
    const promotion = promotions[0]
    const parentSlug =
      service?.parent?.length === 1
        ? `${service.parent[0].slug}/${service.slug}`
        : service.slug
        
    if (typeof window !== `undefined`) {
      navigate(`${buildingSlug}/${parentSlug}/${promotion.slug}`, {
        replace: true,
      })
    }
  }

  return (
    <div>
      <h1>
        {parents.map(parent => {
          const { _id, title, slug } = promoCatAllowed.find(
            cat => cat._id === parent
          )
          buildingSlug += `/${slug}`
          if (_id === service._id) return <span key={_id}>{title}</span>
          return (
            <span key={_id}>
              <Link to={buildingSlug}>{title}</Link> &gt;{" "}
            </span>
          )
        })}
      </h1>
      <div className="service">
        <div className="service-left">
          <SideMenue selected={_id} categories={promoCatAllowed} />
        </div>
        <div className="service-right">
          {info && <div dangerouslySetInnerHTML={{ __html: info }} />}
          <div className="card-grid">
            {children.map(child => (
              <CardService key={child._id} path={path} {...child} />
            ))}
          </div>
          <div className="card-grid">
            {promoAllowed.map(child => (
              <CardPromotion key={child._id} path={path} {...child} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      promotionCategories {
        ...promotionCategoryFields
        children {
          ...promotionCategoryCards
          children {
            ...promotionCategoryCards
          }
        }
        parent {
          ...promotionCategoryCards
        }
        promotions {
          ...promotionCards
        }
      }
    }
  }
`
